// Import mixins from "core" module
@import '../../../mixins';

#mynjappeal {
  /////////////////////////////////////////////////////////////////////////////////////////
  /*  ANT DESIGN CASCADER */

  .ant-cascader-menus {
    border: 1px solid #e9e9e9;
  }
}