#mynjappeal {
  height: 100%;

  [data-amplify-authenticator] {
    background-image: url(../../../../public/resources/images/brt/mynjappealBackgroundAccounting2.jpg);
    background-size: cover;
    display: flex;
    flex-flow: column;
    height: 100%;

    [data-amplify-container] {
      margin-top: 100px;
      background-color: white;

      [data-amplify-form] {
        border-top: #e8e8e8 thin solid;

        .amplify-countrycodeselect {
          display:none;
        }
      }
    }

    .amplify-tabs__list {
      display: none;
    }
  }
}