// Import mixins from "core" module
@import '../../../mixins';

#mynjappeal {
  /////////////////////////////////////////////////////////////////////////////////////////
  /*  ANT DESIGN SLIDER */

  .ant-slider-with-marks {
    margin-bottom: 19px;
  }
}