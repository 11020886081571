// Import mixins from "core" module
@import '../../../mixins';

#mynjappeal {
  /////////////////////////////////////////////////////////////////////////////////////////
  /*  ANT DESIGN SPIN */

  .ant-spin-nested-loading > div > .ant-spin {
    max-height: none;
  }
}