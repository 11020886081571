// Import mixins from "core" module
@import '../../../mixins';

#mynjappeal {
  /////////////////////////////////////////////////////////////////////////////////////////
  /*  ANT DESIGN NOTIFICATION */

  .ant-notification {
    max-width: calc(100vw - 48px);
  }
}