
.greeting {
  display:flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  padding: 10px;

  span {
    font-size: 16px;
  }

  #signOut {
    padding: 0 20px;
    background-color: #f90;
    border: none;
    span {
      font-size: 12px;
      letter-spacing: 1.1px;
      text-transform: uppercase;
    }
  }
}