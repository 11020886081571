@import '../../../resources/mixins';

.footer {
  background: $gray-border;
  padding: rem(3) rem(15) rem(15);
  margin: rem(0) rem(30) 0;
  border-radius: 5px 5px 0 0;

  @media (max-width: $sm-max-width) {
    margin: rem(-25) 0 0;
  }

  &__bottom {
    border-top: 1px solid $gray;
    padding-top:  rem(20);
    margin-top:  rem(10);
  }

  &__copyright {
    margin-top: rem(5);
    float: right;
    display: inline-block;
    position: relative;

    @media(max-width: $xs-max-width) {
      margin-top: rem(20);
      float: none;
    }

    img {
      float: left;
      width: 30px;
      position: absolute;
      top: 6px;
    }
    span {
      display: block;
      margin-left: 50px;
    }
  }
}