// Import mixins from "core" module
@import '../../../mixins';

#mynjappeal {
  /////////////////////////////////////////////////////////////////////////////////////////
  /*  ANT DESIGN INPUT */

  .ant-input-disabled {
    background: $gray-lighten-more-more;
  }
}