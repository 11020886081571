@import '../../../resources/mixins';

.settingsSider {
  position: fixed;
  top: 0;
  right: 0;
  background: $gray-lighten-more;
  width: 256px;
  z-index: 10000;
  height: 100%;
  transform: translate3d(256px, 0,0);
  transition: transform .3s ease-in-out, box-shadow .3s ease-in-out;

  &--opened {
    transform: translate3d(0, 0,0);
    box-shadow: 0 0 200px -20px rgba(57,55,73,.4);
  }

  &__container {
    padding: rem(22) rem(20);
    width: 256px;
  }

  &__title {
    font-weight: bold;
    border-bottom: 1px solid $gray-border;
    padding-bottom: rem(20);
    margin-bottom: rem(20);

    .fa {
      float: right;
      cursor: pointer;
      display: inline-block;
      padding: 4px 2px;
      opacity: .4;

      &:hover {
        opacity: .8;
      }
    }
  }

  &__description {
    font-size: 12px;
    margin-bottom: rem(20);
    color: $text-lighten;
  }

  &__item {
    margin-bottom: rem(15);
  }

  &__itemLabel {
    position: relative;
    top: 2px;
    margin-left: 10px;
  }
}