@import '../../../../resources/mixins';

.menuLeft {

  .ant-layout-sider-children {
    position: fixed;
    height: 100%;
    width: 255px;
    transition: width .3s;
  }

  &.ant-layout-sider-collapsed {
    .ant-layout-sider-children {
      width: 80px;
    }
  }

  &__logo {
    height: 45px;
    //background: $menuBg-darken;
  }

  &__logoContainer {
    height: 64px;
    padding: 13px 20px 15px 22px;
    img {
      height: 36px;
    }
  }

  &__navigation {
    margin-top: rem(15) !important;

    > .ant-menu-item-divider {
      margin: 0 23px !important;
    }

    .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
      right: 50px;
    }

    .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
      border-right: none;
    }

    .ant-menu-dark .ant-menu-inline.ant-menu-sub {
      box-shadow: none !important;
      transition: all .3s;
    }

    &.ant-menu-inline-collapsed {
      .menuLeft__item-title {
        display: none;
      }

      .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
        display: block;
        opacity: .2;
        top: 48%;
        right: 14px;
      }

      .menuLeft__icon {
        right: 30px;
      }
    }

    &.ant-menu-light {

      .menuLeft__icon {
        opacity: .8;
      }

      .ant-menu-submenu-arrow {
        opacity: .7;
      }

      .ant-menu-item:after {
        right: auto;
        left: 0;
      }

      .ant-menu-item, .ant-menu-submenu-title {
        width: 100%;
      }
    }

    &.ant-menu-dark {
      color: $gray-darken;

      .ant-menu-item {
        color: $gray-darken;
      }

      > .ant-menu-item-divider {
        opacity: .2;
      }

      .ant-menu-sub {
        color: $gray-darken;
      }

      .ant-menu-item {
        > a {
          color: $gray-darken;
          &:hover {
            .menuLeft__icon {
              color: $white !important;
            }
          }
        }

      }

      .ant-menu-item-selected {
        > a {
          color: $white;

          .menuLeft__icon {
            color: $white !important;
          }
        }
      }

      .ant-menu-submenu:hover, .ant-menu-submenu-selected, .ant-menu-submenu-open {
        .menuLeft__icon {
          color: $white !important;
        }
      }

      .ant-menu-submenu-active {
        .menuLeft__icon {
          color: $blue !important;
        }
      }

      .ant-menu-inline.ant-menu-sub {
        box-shadow: none !important;
        transition: all .3s;
      }

      .ant-menu-item:after {
        border-right: 3px solid $white !important;
        right: auto;
        left: 0;
      }

    }
  }

  &__icon {
    text-align: center;
    position: absolute;
    right: 20px;
    width: 20px;
    top: 12px;
    margin: 0 !important;
    line-height: 1 !important;
    color: $text;
  }

}

.ant-menu-inline-collapsed-tooltip .menuLeft__icon {
  display: none;
}

.ant-layout-sider-zero-width-trigger {
  top: 10px !important;
}

.ant-menu-item, .ant-menu-submenu-title {
  transition: padding 0s !important;
}