// Import mixins from "core" module
@import '../../../mixins';

#mynjappeal {
  /////////////////////////////////////////////////////////////////////////////////////////
  /*  ANT DESIGN PROGRESS */

  .ant-progress-text {
    white-space: nowrap;
  }
}