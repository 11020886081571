// Import mixins from "core" module
@import '../../../mixins';

#mynjappeal {
/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN BACKTOP */

  .ant-back-top {
    right: rem(50);
    bottom: rem(50);
  }

  .ant-back-top-content {
    border-radius: 5px;
  }
}

