// Import mixins from "core" module
@import '../../../mixins';

#mynjappeal {
  /////////////////////////////////////////////////////////////////////////////////////////
  /*  ANT DESIGN CALENDAR */

  .ant-calendar {
    border: 1px solid #e9e9e9;
  }

  .events {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .events .ant-badge-status {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    font-size: 12px;
  }

  .notes-month {
    text-align: center;
    font-size: 28px;
  }

  .notes-month section {
    font-size: 28px;
  }

  .ant-fullcalendar-month-select {
    margin-left: 5px;
  }

  .ant-calendar-picker-container {
    animation-duration: 0s !important;
  }

  .ant-calendar-range .ant-calendar-input-wrap {
    height: 38px;
  }
}
