// Import mixins from "core" module
@import '../../../mixins';

#mynjappeal {
/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN BADGE */

  .ant-badge-count {
    font-family: inherit;
  }
}
