// Import mixins from "core" module
@import '../../../mixins';

#mynjappeal {
  /////////////////////////////////////////////////////////////////////////////////////////
  /*  ANT DESIGN SELECT */

  .ant-select-dropdown {
    border: 1px solid #e9e9e9;
    animation-duration: 0s !important;
  }

  .ant-select-auto-complete {
    ol, ul, dl {
      margin: 0;
    }
  }

  .ant-select-dropdown {
    animation-duration: 0s !important;
  }
}
